<template>
    <div>
        <h1>Sales</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <CompareBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './sales_opt.vue'
    import GroupBy from './utils/GroupBy.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";
    import CompareBy from "@/components/reports/utils/CompareBy";

    export default {
        name: "sales",
        components: {
            CompareBy,
            Options,
            GroupBy,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startShipDate: {val: (this.$route.query.actual_ship_start) ? this.$route.query.actual_ship_start : new Date().toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    endShipDate: {val: (this.$route.query.actual_ship_end) ? this.$route.query.actual_ship_end : new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    startOrderDate: {val: '', qsField: '[dates][tracker_date_from]'},
                    endOrderDate: {val: '', qsField: '[dates][tracker_date_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    distributorId: {val: (this.$route.query.distributor_search_id) ? this.$route.query.distributor_search_id : '0', qsField: '[numbers][distributor_id]'},
                    customerName: {val: (this.$route.query.distributor_search) ? this.$route.query.distributor_search : ''},
                    distributorCity: {val: 0, qsField: '[strings][a.city]'},
                    companyId: {val: 0, qsField: '[numbers][d.company_id]'},
                    branch: {val:0, qsField: '[numbers][d.primary_branch_id]'},
                    shipState: {val: 0, qsField: '[strings][sa.state]'},
                    schShipFromDate: {val: '', qsField: '[dates][scheduled_ship_from]'},
                    schShipToDate: {val: '', qsField: '[dates][scheduled_ship_to]'},
                    brands: {val: 0, qsField: '[numbers][br.brand_id]'},
                    outsideSalesId: {val: (this.$route.query.outside_sales_user_id) ? this.$route.query.outside_sales_user_id : 0, qsField: '[subquery][outside_sales]'},
                    outsideSalesName: {val: (this.$route.query.outside_sales_user) ? this.$route.query.outside_sales_user : ''},
                    insideSalesId: {val: 0, qsField: '[subquery][inside_sales]'},
                    nationalSalesTeams: {val: 0, qsField: '[numbers][d.national_sales_team_id]'},
                    createdById: {val: 0, qsField: '[numbers][created_by]'},
                    salesRegionId: {val: 0, qsField: '[subquery][outside_sales_region]'},
                    distState: {val: 0, qsField: '[strings][a.state]'},
                    distCity: {val: 0, qsField: '[strings][a.city]'},
                    shiptoTerritory: {val: 0, qsField: '[numbers][t.territory_id]'},
                    specialTag: {val: 0, qsField: '[numbers][d.branch_id]'},
                    quoteId: {val: '', qsField: '[numbers][quote_id]'},
                    schedShipChange: {val: 0, qsField: '[numbers][t.scheduled_ship_change_id]'},
                    flatbed: {val: null, qsField: '[isset][flatbed]'},
                    excludeOnHold: {val: (this.$route.query.exclude) ? null : 'on', qsField: '[isnot][hold]'},
                    onHold: {val: (this.$route.query.onhold) ? this.$route.query.onhold : 0, qsField: '[isset][hold]'},
                    unpaid: {val: null, qsField: '[unpaid]'},
                    placedBy: {val: 0, qsField: '[subquery][order_user_type_id]'},
                    branchId: {val: 0, qsField: '[]'},
                    shipsToCanada: {val: 0, qsField: '[params][ships_to_canada]'},
                    requestedBuildDays:{val: -1, qsField: '[znumbers][t.requested_manufacturing_days]', defaultVal: -1},
                    compareByPeriod: {val:1, label: 'Previous Year', qsField: '[period_type]'},
                    compareByPeriodFields: {
                        prev_period: {val:1, label: 'Previous Year', qsField: '[period_type]'},
                        prev_year: {val:2, label: 'Previous Period', qsField:'[period_type]'},
                    },
                    excludeCdiStock: {val: 1, qsField: '[exclude_cdi_stock]'},
                    numPeriod: {val: 2, label: '1', qsField: ['date_compare_period']},
                    numPeriods: {
                        two: {val: 2, label: '2', qsField: ['date_compare_periods']},
                        three: {val: 3, label: '3', qsField: ['date_compare_periods']},
                        four: {val: 4, label: '4', qsField: ['date_compare_periods']},
                        five: {val: 5, label: '5', qsField: ['date_compare_periods']},
                    },
                    excludeNational: {val: 0, qsField: '[exclude_national]'}
                },
                groupByFields: {
                    company_name: {label: 'Company'},
                    distributor: {label: 'Customer'},
                    shipto_state: {label: 'Ship State'},
                    branch:{ label: 'Branch'},
                    address: { label: 'Cust. Address' },
                    city: {label: 'Cust. City'},
                    state: {label: 'Cust. State'},
                    zip: {label: 'Cust. Zip'},
                    phone: {label: 'Cust. Phone'},
                    price_level: {label: 'Level'},
                    actual_ship: {label: 'Ship Date'},
                    manufacturing_days: {label: 'Manufacturing Days'},
                    outside_sales: {label: 'Regional Sales Rep'},
                    inside_sales: {label: 'Sales Support Rep'},
                    scheduled_ship: {label: 'Scheduled Ship'},
                    cdi_location: {label: 'CDI Location'},
                    territory: {label: 'Territory'},
                },
                dataFields: {
                    tracker_id: {label: 'Order ID', checked: true},
                    tracker_date: {label: 'Order Date', checked: false},
                    actual_ship: {label: 'Ship Date', checked: true},
                    scheduled_ship: {label: 'Sch Ship', checked: false},
                    manufacturing_days: {label: 'Manufacturing Days', checked: false},
                    company_name: {label: 'Company', checked: true},
                    distributor_po: {label: 'PO', checked: false},
                    job_tag: {label: 'Job Tag', checked: false},
                    brand: {label: 'Brand', checked: true},
                    branch: {label: 'Branch', checked: false},
                    distributor: {label: 'Customer', checked: true},
                    address: {label: 'Customer Address', checked: false},
                    zip: {label: 'Customer Zip', checked: false},
                    phone: {label: 'Cust. Phone', checked: false},
                    mphone: {label: 'Cust. Mobile', checked: false},
                    inside_sales: {label: 'Sales Support Rep', checked: false},
                    outside_sales: {label: 'Regional Sales Rep', checked: false},
                    national_sales_team: {label: 'National Sales Team', checked: false},
                    created_by: {label: 'Created By', checked: false},
                    shipto_state: {label: 'Ship State', checked: false},
                    shipto_zip: {label: 'Ship Zip', checked: false},
                    state: {label: 'Cust. State', checked: false},
                    territory: {label: 'Territory', checked: false},
                    city: {label: 'Cust. City', checked: false},
                    price_level: {label: 'Level', checked: false},
                    quote_id : {label: 'Quote ID', checked: false},
                    cdi_location: {label: 'CDI Location', checked: false},
                    payment_term: {label: 'Terms', checked: false},
                    requested_manufacturing_days: {label: 'Requested Build Days', checked: false},
                    surcharge: {label: 'Surcharges', checked: true},
                    measure_fee: {label: 'Measure Fee', checked: true}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Sales']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        },
        destroyed() {
            this.state.isCompareBy = false;
        }
    }
</script>

<style scoped>

</style>