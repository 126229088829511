<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker :displayValue="state.optionFields.startShipDate.val" v-model='state.optionFields.startShipDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model='state.optionFields.endShipDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Order Date From</label>
                <DatePicker v-model='state.optionFields.startOrderDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Order Date To</label>
                <DatePicker v-model='state.optionFields.endOrderDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Customer</label>
              <Typeahead cdi_type='search_my_users' @objectSelected="setCustomer" :displayValue="state.optionFields.customerName.val" v-bind:ID.sync='state.optionFields.distributorId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer City</label>
                <Typeahead cdi_type="city" v-bind:ID.sync="state.optionFields.distCity.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Branch</label>
                <Typeahead cdi_type="branch" v-bind:ID.sync="state.optionFields.branch.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Ship State</label>
                <select class="form-control" v-model="state.optionFields.shipState.val">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>

            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type=checkbox :true-value="'on'" :false-value=null v-model="state.optionFields.shipsToCanada.val"> Ships to Canada</label>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Sch Ship From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.schShipFromDate.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Sch Ship To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.schShipToDate.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Brands</label>
                <select class="form-control" v-model="state.optionFields.brands.val">
                    <option value="0">All</option>
                    <option v-for="[id, brand] in cache.brandCache.BRANDS" v-bind:key="id" :value="id">{{ brand }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Regional Sales Rep</label>
                <Typeahead ref="regionalSalesRep" cdi_type="sales_employee" :displayValue="state.optionFields.outsideSalesName.val" v-bind:ID.sync="state.optionFields.outsideSalesId.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Sales Support Rep</label>
                <Typeahead ref="salesSupportRep" cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type=checkbox :true-value="1" :false-value=0 v-model="state.optionFields.excludeNational.val"> Exclude National</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Created By</label>
                <Typeahead ref="createdBy" cdi_type="user" v-bind:ID.sync="state.optionFields.createdById.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Sales Region</label>
                <select class="form-control" v-model="state.optionFields.salesRegionId.val">
                    <option value="0">None</option>
                    <option v-for="[id, salesPerson] in cache.salesDataCache.SALES_TEAM" v-bind:key="id">{{ salesPerson }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Cust. State</label>
                <select v-model="state.optionFields.distState.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="'dist' + stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Territory</label>
                <select class="form-control" v-model="state.optionFields.shiptoTerritory.val">
                    <option value="0">All</option>
                    <option v-for="[id, territory] in cache.territoryCache" v-bind:key="id" :value="id">{{ territory }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Special Tag</label>
                <select v-model="state.optionFields.specialTag.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, tag] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="id" :value="id">{{ tag }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Quote ID</label><input type="text" class='form-control' v-model="state.optionFields.quoteId.val">
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Sched Ship Change</label>
                <select v-model="state.optionFields.schedShipChange.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, shipChange] in cache.shippingCache.SCHEDULED_SHIP_CHANGE_REASONS" v-bind:key="id" :value="id">{{ shipChange }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox :true-value="'on'" :false-value=null v-model="state.optionFields.flatbed.val"> Flatbed</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox :true-value="'on'" :false-value=null v-model="state.optionFields.excludeOnHold.val"> Exclude On-Hold</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox :true-value="'1'" :false-value=null v-model="state.optionFields.onHold.val"> On Hold</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox :true-value="'1'" :false-value=null v-model="state.optionFields.excludeCdiStock.val"> Exclude CDI Stock</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox :true-value="'on'" :false-value=null v-model="state.optionFields.unpaid.val"> Unpaid</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide">
                <label>Requested Build Days</label>
                <select class="form-control" v-model="state.optionFields.requestedBuildDays.val">
                    <option value="-1">All</option>
                    <option value="0">Same Day</option>
                    <option value="1">Next Day</option>
                    <option value="2">3 Day</option>
                    <option value="3">4 Day</option>
                    <option value="4">5 Day</option>
                    <option value="5">6 Day</option>
                    <option value="6">7 Day</option>
                    <option value="7">8 Day</option>
                    <option value="8">9 Day</option>
                </select>
            </div>
            <div class='col-xs-12' v-show="!toggleHide"><div class="filter_separator">Order Placed By</div></div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type='radio' v-model="state.optionFields.placedBy.val" value="4"> Staff</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type='radio' v-model="state.optionFields.placedBy.val" value="1,2,8"> Customer</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type='radio' v-model="state.optionFields.placedBy.val" value="0"> All</label>
            </div>
            <div class="col-xs-12"><button type="button" @click="toggleMoreFilters()" class="btn btn-default btn-sm">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import stateCache from '@/cache/state.cache.js';
    import brandCache from '@/cache/brand.cache.js';
    import salesDataCache from '@/cache/salesData.cache.js';
    import userCache from '@/cache/user.cache.js';
    import shippingCache from '@/cache/shipping.cache.js';
    import DatePicker from "@/components/utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Options",
        components: { DatePicker, Typeahead },

        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    stateCache,
                    brandCache,
                    salesDataCache,
                    userCache,
                    shippingCache,
                    territoryCache: []
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
            }
        },
      created() {
        this.get_national_sales_teams();
        this.getTerritories();

      },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.createdBy.object = '';
                    this.$refs.salesSupportRep.object = '';
                    this.$refs.regionalSalesRep.object = '';
                }
            },
            setCustomer: function(userData) {
                this.state.optionFields.distributorId.val = userData.id;
            },
            get_national_sales_teams: function() {
              store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
            },
            getTerritories: function() {
                let scope = this;
                store.customAPI('menu_builder/sales_territories', {}).then(function(res) {
                    scope.cache.territoryCache = res;
                });
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }

    }
</script>

<style scoped>

</style>